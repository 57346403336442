<template>
    <div style="background-color: #ffffff">
        <el-form
                class="detail-form-content"
                :model="form" :rules="rules" ref="ruleUser"
                label-width="110px"
        >
            <el-row>
                <h2 style="padding: 20px;font-weight: bolder">个人信息</h2>
                <el-col :span="24">
                    <el-form-item  label="用户名" prop="name">
                        <el-input v-model="form.name" placeholder="姓名" clearable style="width: 200px;"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item  label="邮箱" prop="email">
                        <el-input v-model="form.email" placeholder="邮箱" clearable style="width: 200px;"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item  label="手机号" prop="phone">
                        <el-input v-model="form.phone" placeholder="手机号" clearable style="width: 200px;"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24" v-if="this.userinfo.role=='21'||this.userinfo.role=='23'">
                    <el-form-item  label="部门/学院">
                        <el-input v-model="form.collegeinfo.name" disabled style="width: 200px;"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24" v-if="this.userinfo.role=='23'">
                    <el-form-item  label="专业">
                        <el-input v-model="form.majorinfo.majorname" disabled style="width: 200px;"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" @click="operatorUser">修 改</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script>
    import { updateUser,getUserByid } from "@/request/requests";
    import { MessageSuccess,MessageError } from "@/util/util"
    export default {
        name: "user",
        data(){
            return {
                userinfo:{},
                form:this.initForm(),
                formLabelWidth:"100px",
                rules: {
                    id :[
                        {required: true, message: '请输入主键id', trigger: 'blur'},],
                    name :[
                        {required: true, message: '请输入用户名', trigger: 'blur'},],
                    // password :[
                    //     {required: true, message: '请输入密码', trigger: 'blur'},],
                    email :[
                        {required: true, message: '请输入邮箱', trigger: 'blur'},],
                    phone :[
                        {required: true, message: '请输入手机号', trigger: 'blur'},],
                    role :[
                        {required: true, message: '请输入用户角色', trigger: 'blur'},],
                    createTime :[
                        {required: true, message: '请输入创建时间', trigger: 'blur'},],
                    updateTime :[
                        {required: true, message: '请输入更新时间', trigger: 'blur'},],
                    ext1 :[
                        {required: true, message: '请输入扩展字段', trigger: 'blur'},],
                    ext2 :[
                        {required: true, message: '请输入扩展字段', trigger: 'blur'},],
                },
            }
        },
        methods:{
            initForm() {//初始数据
                return {
                    id : "", // 主键id
                    name : "", // 用户名
                    password : "", // 密码
                    email : "", // 邮箱
                    phone : "", // 手机号
                    role : "", // 用户角色
                    createTime : "", // 创建时间
                    updateTime : "", // 更新时间
                    ext1 : "", // 扩展字段
                    ext2 : "", // 扩展字段
                }
            },
            async operatorUser() {
                let  res = await updateUser(this.form)
                if (res.status == "200") {
                    MessageSuccess(res.msg)
                } else {
                    MessageError(res.msg)
                }
            },
            getUserInfo(){
                let deadline = sessionStorage.getItem('deadline');
                let userInfo = sessionStorage.getItem("userInfo");
                let token = sessionStorage.getItem('token');
                if(!deadline||!userInfo||!token) {
                    this.removeInfo()
                    MessageError("请先登录！")
                    this.$router.push({ path: "/login" });
                    return false
                }else{
                    let now = new Date().getTime();
                    if (deadline<now){
                        this.removeInfo()
                        MessageError("请先登录！")
                        this.$router.push({ path: "/login" });
                        return false
                    }
                }
                return userInfo;
            },
            removeInfo(){
                sessionStorage.removeItem("userInfo");
                sessionStorage.removeItem("deadline");
                sessionStorage.removeItem("token");
            },
            async getUserByidInfo() {
                let res = await getUserByid(this.userinfo.id)
                if (res.status == "200") {
                    this.form=res.data.list[0]
                } else {
                    MessageError(res.msg)
                }
            }
        },
        created() {
            let userInfo=this.getUserInfo()
            if (userInfo){
                this.userinfo=JSON.parse(userInfo)
                this.getUserByidInfo()
            }
        }
    }
</script>

<style scoped>

</style>
